<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="subadmin">
        <heade></heade>
        <div class="con">
            <div class="box">
                <p class="p1">设置子管理员</p>
                <div class="nei">
                    <div class="nei_top">
                        <p class="p2" @click="dianji_tianjia">添加子管理员</p>
                        <p class="p3" @click="dianji_shanchu">批量删除</p>
                    </div>
                    <el-table ref="multipleTable" :cell-style="liebiao" :header-cell-style="biaotou" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="70">
                        </el-table-column>
                        <!-- <el-table-column  prop="dept_name" label="部门" width="240">
                        </el-table-column> -->
                        <el-table-column prop="staff_name"  label="姓名" width="300">
                        </el-table-column>
                        <el-table-column  prop="quanxian_name" label="权限">
                        </el-table-column>
                        <!-- <el-table-column label="" width="300">
                            <template>
                                <el-button type="text" size="small" style="color:#8d52e3">编辑</el-button>
                            </template>
                        </el-table-column> -->
                    </el-table>
                </div>
            </div>
        </div>
        <el-drawer
            :visible.sync="drawer"
            :modal='false'
            :show-close='false'
            title="子管理员"
            size="620">
            <div style="color:#888888;font-size:0.18rem;height:0.42rem;width:94%;background:#E7E8EB;margin:0 auto;line-height:0.42rem;padding:0 0.12rem;min-width:3.88rem;">子管理员信息</div>
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>选择权限
                </li>
                <li class="dd5">
                    <!-- <div style="margin-right:0.44rem;width:0.9rem;"><input ref="quanbu" type="checkbox" @click="dianji_quanxian_quanbu">全部</div> -->
                    <div class='xuanze2'>
                        <p v-for="(i,index) in quanxian" :key="index" @click="dianji_xuanze_mokuai(i,index)">
                            <!-- <input ref="quana" type="checkbox" @click="dianji_quanxian(i,$event,index)"> -->
                            <img :src="modal_num==index?require('../assets/shixin.png'):require('../assets/kongxin.png')" alt="">
                            {{i.name}}</p>
                    </div>
                </li>
            </ul>
            <ul v-if="modal_num!='-1'" class="liebiao" @click="dakai_bumen">
                <li class="dd1">
                    <span>*</span>选择子管理员
                </li>
                <li class="dd2"><input type="text" placeholder=请选择子管理员  v-model="ziguanliyuan"></li>
            </ul>
            <div class="xuan_foot">
                <div style="color:#fff;background:#8d52e3" @click="box_queding">确定</div>
                <div style="color:#888888;border:0.01rem solid #888888" @click="drawer = false">取消</div>
            </div>
        </el-drawer>
        <!-- <el-dialog title="选择人员" :center="true" :show-close='false' :visible.sync="qiye_bumen">
            <div class="tiao_bumen">
                <div class="xuanze">
                    <p class="tit">选择：</p>
                    <div class="xuanze_sousuo">
                        <i class="el-icon-search"></i>
                        <input type="text" placeholder="搜索" @keyup="keyu" v-model="text">
                    </div>
                    <div class="mianbaoxie">
                        <div v-for="(i,index) in mianbao_list" :key='index'  @click="mianbao_dian(i,index)">
                            <p :class="mianbao_list.length-1==index?'p1':'p2'">{{i.name}}</p>
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="quanxuan" v-if="qiye_renyuan_list.length!=0"><input type="checkbox" @click="dianji_quanxuan" ref='quan'><span>全选</span></div>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(i,index) in qiye_bumen_list" :key="index" @click="dianji_bumen(i)">
                                    <p style="margin-left:0.16rem;">{{i.dept_name}}</p>
                                    <img class="img1" :src="i.child_flag=='0'?require('../assets/chazi.png'):require('../assets/chazi_ok.png')" alt="">
                                </li>
                            </ul>
                            <ul>
                                <li v-for="(i,index) in qiye_renyuan_list" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
                                    <input ref="have" type="checkbox" @click="dianji_xuanren(i,$event,index)">
                                    <p>{{i.name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="yixuan">
                    <p class="tit">已选：</p>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(i,index) in qiye_renyuan_list_xuan" :key="index">
                                    <p>{{i.name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="foote">
                <p class="queding" @click="dianji_queding">确定</p>
                <p class="quxiao" @click="qiye_bumen=false">取消</p>
            </div>
        </el-dialog> -->
        <el-dialog title="选择人员" :center="true" :show-close='false' :visible.sync="qiye_bumen">
            <div class="tiao_bumen">
                <div class="xuanze">
                    <p class="tit">选择：</p>
                    <div class="xuanze_sousuo">
                        <i class="el-icon-search"></i>
                        <input type="text" placeholder="搜索" @keyup="keyu" v-model="text" style="padding-left: 50px;">
                    </div>
                    <div class="mianbaoxie">
                        <div v-for="(i,index) in mianbao_list" :key='index'  @click="mianbao_dian(i,index)">
                            <p :class="mianbao_list.length-1==index?'p1':'p2'">{{i.name}}</p>
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <!-- <div class="quanxuan" v-if="qiye_renyuan_list.length!=0">
                        <img @click="dianji_quanxuan" :src="renyuan_quanxuan?require('../assets/okblue.png'):require('../assets/noccc.png')" alt="">
                        <span>全选</span>
                    </div> -->
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul v-if="!text">
                                <li v-for="(i,index) in qiye_bumen_list" :key="index" @click="dianji_bumen(i)">
                                    <p style="margin-left:0.16rem;">{{i.dept_name}}</p>
                                    <!-- <img class="img1" :src="i.child_flag=='0'?require('../assets/chazi.png'):require('../assets/chazi_ok.png')" alt=""> -->
                                </li>
                            </ul>
                            <ul>
                                <li v-for="(i,index) in qiye_renyuan_list" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
                                    <!-- <input ref="have" type="checkbox" @click="dianji_xuanren(i,$event,index)"> -->
                                    <img class="img2" @click="dianji_xuanren(i)" :src="i.zhi?require('../assets/okblue.png'):require('../assets/noccc.png')" alt="">
                                    <p>{{i.name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="yixuan">
                    <p class="tit">已选：</p>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li class="youce_teshu" v-for="(i,index) in qiye_renyuan_list_xuan" :key="index">
                                    <p>{{i.staff_name}}</p>
                                    <i @click="dianji_shanchu_yixuan(i)" class="el-icon-error"></i>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="foote">
                <p class="queding" @click="dianji_queding">确定</p>
                <p class="quxiao" @click="qiye_bumen=false">取消</p>
            </div>
        </el-dialog>
        <foote></foote>
    </div>
</template>

<script>
import heade from '../components/Heade'
import foote from '../components/Foote'
import { query_ent_sub_manager_list, query_ent_sub_manager_list_by_module, query_ent_sub_manager, query_ent_dept_list, query_dept_staff_list, set_ent_sub_manager, update_ent_sub_manager, delete_ent_sub_manager, get_staff_list_like } from '../api/api'
export default {
  name: 'subadmin',
  data () {
    return {
      tableData: [],
      ent_id: '',
      drawer: false,
      qiye_bumen: false,
      mianbao_list: [
        {
          name: '',
          dept_id: '0'
        }
      ],
      qiye_bumen_list: [],
      qiye_renyuan_list: [],
      qiye_renyuan_list_xuan: [],
      ziguanliyuan: '',
      ziguanliyuan_bei: [],
      quanxian: [
        // {
        //     num:'1',
        //     name:'目标',
        // },
        {
          num: '1-2',
          name: '目标-销售'
        },
        // {
        //     num:'1-3',
        //     name:'目标-产品',
        // },
        // {
        //     num:'1-4',
        //     name:'目标-核算',
        // },
        {
          num: '2',
          name: '销售'
        },
        {
          num: '3',
          name: '产品'
        },
        {
          num: '4',
          name: '核算'
        }
        // ,
        // {
        //     num:'5',
        //     name:'风控',
        // },
        // {
        //     num:'6',
        //     name:'成长',
        // },
      ],
      renyuan_quanxian_list: [],
      hang: '1',
      text: '',
      jichuxinxi: '',
      modal_num: '-1',
      nengxuan_renshu: '3',
      mokuai_shengyu_num: []
    }
  },
  created () {
    if (sessionStorage.getItem('pc_mlbb_ent_info') != null && sessionStorage.getItem('pc_mlbb_ent_info') != undefined && sessionStorage.getItem('pc_mlbb_ent_info') != '') {
      this.ent_id = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_id
    }
    if (sessionStorage.getItem('pc_mlbb_jichuxinxi') != null && sessionStorage.getItem('pc_mlbb_jichuxinxi') != undefined && sessionStorage.getItem('pc_mlbb_jichuxinxi') != '') {
      this.jichuxinxi = JSON.parse(JSON.parse(sessionStorage.getItem('pc_mlbb_jichuxinxi')))
    }
    this.mianbao_list[0].name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
  },
  mounted () {
    this.jichu()
  },
  watch: {
    drawer () {
      if (!this.drawer) {
        this.qiye_renyuan_list_xuan = []
        this.modal_num = '-1'
      } else {
        this.chaxun_mokuai_shengyu_renshu()
      }
    }
  },
  components: { heade, foote },
  methods: {
    //  最开始加载的文字
    jichu () {
      // query_ent_sub_manager({data:{
      //     ent_id:this.ent_id
      // }}).then(res=>{
      //     console.log(res)
      //     if(res.data.code==10109){
      //         if(res.data.body!=null){
      //             let date=JSON.parse(res.data.body.data)
      //             console.log(date)
      //             for(let i=0;i<date.length;i++){
      //                 let data=date[i].user_rel.split(',')
      //                 for(let a=0;a<data.length;a++){
      //                     if(data[a]==1){
      //                         data[a]='目标'
      //                     }
      //                     if(data[a]==2){
      //                         data[a]='销售'
      //                     }
      //                     if(data[a]==3){
      //                         data[a]='产品'
      //                     }
      //                     if(data[a]==4){
      //                         data[a]='核算'
      //                     }
      //                     if(data[a]==5){
      //                         data[a]='风控'
      //                     }
      //                     if(data[a]==6){
      //                         data[a]='成长'
      //                     }
      //                 }
      //                 // if(data.length==6){
      //                 //     date[i]['user_rela']='全部'
      //                 // }else{
      //                     date[i]['user_rela']=data.join(',')
      //                 // }
      //                 console.log(data)
      //                 date[i]['bianji']='编辑'
      //             }
      //             console.log(date)
      //             this.tableData=date
      //         }
                    
      //     }else if(res.data.code==10110){
      //     }
      // })
      query_ent_sub_manager_list({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date.length != 0) {
            for (let i = 0; i < date.length; i++) {
              const module_list = date[i].module_id.split(',')
              const modal_name = []
              for (let a = 0; a < module_list.length; a++) {
                if (module_list[a] == '1-2') {
                  modal_name.push('目标-销售')
                } else if (module_list[a] == '1-3') {
                  modal_name.push('目标-产品')
                } else if (module_list[a] == '1-4') {
                  modal_name.push('目标-核算')
                } else if (module_list[a] == '2') {
                  modal_name.push('销售')
                } else if (module_list[a] == '3') {
                  modal_name.push('产品')
                } else if (module_list[a] == '4') {
                  modal_name.push('核算')
                }
              }
              date[i].quanxian_name = modal_name.join(',')
                            
              // .map(item=>{
              //     this.$set(item,'quanxian_name',modal_name.join(','))
              // })
            }
            // date.map(item=>{
            //     this.$set(item,'quanxian_name',item.module_id==1?'目标':(item.module_id==2?'销售':(item.module_id==3?'产品':'核算')))
            // })
          }
          this.tableData = date
          // }
        } else if (res.data.code == 500) {}
      })
    },
    chaxun_mokuai_shengyu_renshu () {
      query_ent_sub_manager_list_by_module({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          this.mokuai_shengyu_num = []
          // this.mokuai_shengyu_num.push({
          //     modal:'1',
          //     shengyu:'3'
          // })
          this.mokuai_shengyu_num.push({
            modal: '1-2',
            shengyu: '3'
          })
          this.mokuai_shengyu_num.push({
            modal: '1-3',
            shengyu: '3'
          })
          this.mokuai_shengyu_num.push({
            modal: '1-4',
            shengyu: '3'
          })
          this.mokuai_shengyu_num.push({
            modal: '2',
            shengyu: '3'
          })
          this.mokuai_shengyu_num.push({
            modal: '3',
            shengyu: '3'
          })
          this.mokuai_shengyu_num.push({
            modal: '4',
            shengyu: '3'
          })
          if (date.length != 0) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            for (let i = 0; i < date.length; i++) {
              for (let a = 0; a < this.mokuai_shengyu_num.length; a++) {
                if (date[i].module_id == this.mokuai_shengyu_num[a].modal) {
                  this.$set(this.mokuai_shengyu_num[a], 'staff_id', date[i].list.map(item => item.staff_id).join(','))
                  this.$set(this.mokuai_shengyu_num[a], 'staff_name', date[i].list.map(item => item.staff_name).join(','))
                  // this.mokuai_shengyu_num[a].shengyu=date[i].staff_num
                  this.$set(this.mokuai_shengyu_num[a], 'list', date[i].list)
                }
              }
            }
            // qiye_renyuan_list_xuan
          }
        } else if (res.data.code == 500) {}
      })
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
      console.log(9999999, val)
    },
    // 点击添加子管理员
    dianji_tianjia () {
      this.drawer = true
      this.hang = '1'
      this.ziguanliyuan = ''
    },
    // 部门列表
    bumen_liebiao () {
      query_ent_dept_list({
        data: {
          ent_id: this.ent_id,
          active: '1',
          parent_id: this.mianbao_list[this.mianbao_list.length - 1].dept_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.qiye_bumen_list = date
        } else if (res.data.code == 10169) {
        }
      })
      query_dept_staff_list({
        data: {
          ent_id: this.ent_id,
          user_id: this.jichuxinxi.user_id,
          dept_id: this.mianbao_list[this.mianbao_list.length - 1].dept_id,
          active: '1'
        }
      }).then(ras => {
        if (ras.data.code == 10149) {
          const listdata = JSON.parse(ras.data.body.data)
          console.log(99999, listdata)
          listdata.map(item => { item.zhi = false })
          this.qiye_renyuan_list = listdata
        } else if (ras.data.code == 10150) {
        }
      })
    },
    dakai_bumen () {
      this.mianbao_list.splice(1, this.mianbao_list.length)
      this.bumen_liebiao()
      this.qiye_bumen = true
    },
    // // 点击全选
    // dianji_quanxuan(e){
    //     console.log(e)
    //     console.log(this.$refs.have)
    //     if(e.path[0].checked){
    //         for(let i=0;i<this.$refs.have.length;i++){
    //             this.$refs.have[i].checked=true
    //         }
    //         this.qiye_renyuan_list_xuan=[]
    //         for(let i=0;i<this.qiye_renyuan_list.length;i++){
    //             this.qiye_renyuan_list_xuan.push(this.qiye_renyuan_list[i])
    //         }
    //         console.log(this.qiye_renyuan_list_xuan)
    //     }else{
    //         for(let i=0;i<this.$refs.have.length;i++){
    //             this.$refs.have[i].checked=false
    //         }
    //         this.qiye_renyuan_list_xuan=[]
    //     }
    // },
    // // 单选人
    // dianji_xuanren(i,e,index){
    //     console.log(e)
    //     if(e.path[0].checked==true){
    //         this.qiye_renyuan_list_xuan.push(i)
    //     }else{
    //         console.log(this.qiye_renyuan_list_xuan)
    //         for(let i=0;i<this.qiye_renyuan_list_xuan.length;i++){
    //             if(this.qiye_renyuan_list[index].name==this.qiye_renyuan_list_xuan[i].name){
    //                 this.qiye_renyuan_list_xuan.splice(i,1)
    //             }
    //         }
    //     }
    // },
    // // 点击部门
    // dianji_bumen(i){
    //     console.log(44444,i)
    //     if(i.child_flag==1){
    //         query_ent_dept_list({data:{
    //             ent_id:this.ent_id,
    //             user_id:this.jichuxinxi.user_id,
    //             parent_id:i.dept_id,
    //             active:'1'
    //         }}).then(res=>{
    //             if(res.data.code==10168){
    //                 let date=JSON.parse(res.data.body.data)
    //                 console.log(date)
    //                 this.qiye_bumen_list=date
    //                 this.mianbao_list.push({name:i.dept_name,dept_id:i.dept_id})
    //                 this.inde=-1
    //                 if(!this.bumen){
    //                     query_dept_staff_list({data:{
    //                         ent_id:this.ent_id,
    //                         user_id:this.jichuxinxi.user_id,
    //                         dept_id:i.dept_id,
    //                         active:'1'
    //                     }}).then(ras=>{
    //                         if(ras.data.code==10149){
    //                             let listdata=JSON.parse(ras.data.body.data)
    //                             console.log(99999,listdata)
    //                             this.qiye_renyuan_list=listdata
    //                         }else if(ras.data.code==10150){
    //                         }
    //                     })
    //                 }
    //             }else if(res.data.code==10169){
    //             }
    //         })
    //     }else{
    //         this.$message({
    //             message: '该部门没有子部门了',
    //             type: 'warning'
    //         }); 
    //     }
    // },
    // // 点击面包屑进行页面内容切换
    // mianbao_dian(i,index){
    //     console.log(i.dept_id)
    //     if(this.mianbao_list.length-1!=index){
    //         query_ent_dept_list({data:{
    //             ent_id:this.ent_id,
    //             user_id:this.jichuxinxi.user_id,
    //             parent_id:i.dept_id,
    //             active:'1'
    //         }}).then(res=>{
    //             if(res.data.code==10168){
    //                 var date=JSON.parse(res.data.body.data)
    //                 // console.log(date)
    //                 this.qiye_bumen_list=date
    //                 this.mianbao_list.splice(index+1,this.mianbao_list.length)
    //                 query_dept_staff_list({data:{
    //                     ent_id:this.ent_id,
    //                     user_id:this.jichuxinxi.user_id,
    //                     dept_id:i.dept_id,
    //                     active:'1'
    //                 }}).then(ras=>{
    //                     console.log(ras)
    //                     if(ras.data.code==10149){
    //                         let listdata=JSON.parse(ras.data.body.data)
    //                         console.log(99999,listdata)
    //                         this.qiye_renyuan_list=listdata
    //                     }else if(ras.data.code==10150){
    //                     }
    //                 })
    //             }else if(res.data.code==10169){
    //             }
    //         })
    //     }
    // },
    // 点击全选
    dianji_quanxuan () {
      this.renyuan_quanxuan = !this.renyuan_quanxuan
      if (this.renyuan_quanxuan) {
        this.qiye_renyuan_list.map(item => {
          item.zhi = true
          this.qiye_renyuan_list_xuan.push(item)
        })
        this.qiye_renyuan_list_xuan = this.$func.Es5duplicate(this.qiye_renyuan_list_xuan, 'staff_id')
      } else {
        this.qiye_renyuan_list.map(item => { item.zhi = false })
        for (let i = 0; i < this.qiye_renyuan_list.length; i++) {
          for (let a = 0; a < this.qiye_renyuan_list_xuan.length; a++) {
            if (this.qiye_renyuan_list[i].staff_id == this.qiye_renyuan_list_xuan[a].staff_id) {
              this.qiye_renyuan_list_xuan.splice(a, 1)
            }
          }
        }
      }
    },
    panduan_yixuan () {
      const list = []
      for (let i = 0; i < this.qiye_renyuan_list.length; i++) {
        for (let a = 0; a < this.qiye_renyuan_list_xuan.length; a++) {
          if (this.qiye_renyuan_list[i].staff_id == this.qiye_renyuan_list_xuan[a].staff_id) {
            this.qiye_renyuan_list[i].zhi = true
            list.push('1111')
          }
        }
      }
      if (this.qiye_renyuan_list.length != 0) {
        if (list.length == this.qiye_renyuan_list.length) {
          this.renyuan_quanxuan = true
        } else {
          this.renyuan_quanxuan = false
        }
      }
    },
    dianji_xuanren (i) {
      if (this.qiye_renyuan_list_xuan.length != this.nengxuan_renshu || i.zhi) {
        if (!i.zhi) {
          this.qiye_renyuan_list_xuan.push(i)
        } else {
          for (let a = 0; a < this.qiye_renyuan_list_xuan.length; a++) {
            if (i.staff_id == this.qiye_renyuan_list_xuan[a].staff_id) {
              this.qiye_renyuan_list_xuan.splice(a, 1)
            }
          }
        }
        this.panduan_shifou_quanxuan()
        i.zhi = !i.zhi
      } else {
        this.$message({
          message: '当前模块只能选择' + this.nengxuan_renshu + '个人',
          type: 'warning'
        })
      }
    },
    panduan_shifou_quanxuan () {
      const list = []
      console.log(this.qiye_renyuan_list)
      for (let i = 0; i < this.qiye_renyuan_list.length; i++) {
        if (this.qiye_renyuan_list[i].zhi) {
          list.push('1111')
        }
      }
      if (this.qiye_renyuan_list.length != 0) {
        if (list.length == this.qiye_renyuan_list.length) {
          this.renyuan_quanxuan = true
        } else {
          this.renyuan_quanxuan = false
        }
      }
    },
    // 点击权限
    // dianji_quanxian(i,e,index){
    //     console.log(e)
    //     if(e.path[0].checked==true){
    //         this.renyuan_quanxian_list.push(i)
    //     }else{
    //         console.log(this.renyuan_quanxian_list)
    //         for(let a=0;a<this.renyuan_quanxian_list.length;a++){
    //             console.log(111111,this.quanxian[index])
    //             if(this.quanxian[index].name==this.renyuan_quanxian_list[a].name){
    //                 this.renyuan_quanxian_list.splice(a,1)
    //             }
    //         }
    //     }
    //     console.log(this.renyuan_quanxian_list)
    // },
    // 权限全部选择
    // dianji_quanxian_quanbu(e){
    //     console.log(e)
    //     if(e.path[0].checked){
    //         for(let i=0;i<this.$refs.quana.length;i++){
    //             this.$refs.quana[i].checked=true
    //         }
    //         this.renyuan_quanxian_list=[]
    //         for(let i=0;i<this.quanxian.length;i++){
    //             this.renyuan_quanxian_list.push(this.quanxian[i])
    //         }
    //     }else{
    //         for(let i=0;i<this.$refs.quana.length;i++){
    //             this.$refs.quana[i].checked=false
    //         }
    //         this.renyuan_quanxian_list=[]
    //     }
    // },
    // 子管理员添加信息
    box_queding () {
      if (this.ziguanliyuan_bei.length != 0 && this.modal_num != -1) {
        set_ent_sub_manager({
          data: {
            ent_id: this.$ent_id(),
            module_id: this.quanxian[this.modal_num].num + '',
            staff_ids: this.ziguanliyuan_bei.map(item => item.staff_id).join(',')
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.drawer = false
            this.jichu()
          } else if (res.data.code == 500) {}
        })
      } else {
        this.$message({
          message: '请重新审查填写选项，是否有遗漏',
          type: 'warning'
        }) 
      }
    },
    hangdian (val) {
      console.log(11111, val)
      this.drawer = true
      this.ziguanliyuan = val.name
      this.ziguanliyuan_bei = [{ staff_id: val.staff_id }]
      this.hang = '2'
      console.log(this.ziguanliyuan_bei)
      console.log(this.tableData)
      const date = val.user_rel.split(',')
      if (date.length == 6) {
        setTimeout(() => {
          for (let i = 0; i < this.quanxian.length; i++) {
            this.$refs.quana[i].checked = true
            this.renyuan_quanxian_list.push(this.quanxian[i])
          }
        }, 0)
      } else {
        console.log(1111)
        setTimeout(() => {
          for (let i = 0; i < this.quanxian.length; i++) {
            for (let a = 0; a < date.length; a++) {
              if (date[a] == this.quanxian[i].num) {
                this.$refs.quana[i].checked = true
                this.renyuan_quanxian_list.push(this.quanxian[i])
              }
            }
          }
        }, 0)
      }
    },
    // 点击批量删除
    dianji_shanchu () {
      if (this.multipleSelection != undefined) {
        const date = this.multipleSelection
        const list = []
        for (let i = 0; i < date.length; i++) {
          list.push({
            module_id: date[i].module_id,
            staff_id: date[i].staff_id
          })
        }
        delete_ent_sub_manager({
          data: {
            ent_id: this.ent_id,
            list: list
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.jichu()
          } else if (res.data.code == 500) {
            this.$message({
              message: '删除失败',
              type: 'warning'
            }) 
          }
        })
      } else {
        this.$message({
          message: '请选择人员再删除',
          type: 'warning'
        }) 
      }
    },
    keyu(){
        // if(this.text.length==0){
        //     this.text=null
        // }
        get_staff_list_like({data:{
            ent_id:this.ent_id,
            user_id:this.jichuxinxi.user_id,
            staff_name:this.text,
            active:'1'
        }}).then(res=>{
            console.log(res)
            if(res.data.code==10027){
              console.log(JSON.parse(res.data.body.data))
              let date=JSON.parse(res.data.body.data)
              date.forEach(item => {
                item.name = item.staff_name
              })
              this.qiye_bumen_list = []
        //this.qiye_bumen_list = []
        // this.qiye_renyuan_list = date
              this.qiye_renyuan_list = date
            }else if(res.data.code==10028){
            }
        })
    },
    // 点击部门
    dianji_bumen (i) {
      console.log(44444, i)
      // if(i.child_flag==1){
      query_ent_dept_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          parent_id: i.dept_id,
          active: '1'
        }
      }).then(res => {
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.qiye_bumen_list = date
          this.mianbao_list.push({ name: i.dept_name, dept_id: i.dept_id })
          this.inde = -1
          if (!this.bumen) {
            query_dept_staff_list({
              data: {
                ent_id: this.$ent_id(),
                user_id: this.$jichuxinxi().user_id,
                dept_id: i.dept_id,
                active: '1'
              }
            }).then(ras => {
              if (ras.data.code == 10149) {
                const listdata = JSON.parse(ras.data.body.data)
                console.log(99999, listdata)
                listdata.map(item => { item.zhi = false })
                this.qiye_renyuan_list = listdata
                this.panduan_yixuan()
              } else if (ras.data.code == 10150) {
              }
            })
          }
        } else if (res.data.code == 10169) {
        }
      })
      // }else{
      //     this.$message({
      //         message: '该部门没有子部门了',
      //         type: 'warning'
      //     }); 
      // }
    },
    // 点击面包屑进行页面内容切换
    mianbao_dian (i, index) {
      console.log(i.dept_id)
      if (this.mianbao_list.length - 1 != index) {
        query_ent_dept_list({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            parent_id: i.dept_id,
            active: '1'
          }
        }).then(res => {
          if (res.data.code == 10168) {
            var date = JSON.parse(res.data.body.data)
            // console.log(date)
            this.qiye_bumen_list = date
            this.mianbao_list.splice(index + 1, this.mianbao_list.length)
            query_dept_staff_list({
              data: {
                ent_id: this.$ent_id(),
                user_id: this.$jichuxinxi().user_id,
                dept_id: i.dept_id,
                active: '1'
              }
            }).then(ras => {
              console.log(ras)
              if (ras.data.code == 10149) {
                const listdata = JSON.parse(ras.data.body.data)
                console.log(99999, listdata)
                listdata.map(item => { item.zhi = false })
                this.qiye_renyuan_list = listdata
                this.panduan_yixuan()
              } else if (ras.data.code == 10150) {
              }
            })
          } else if (res.data.code == 10169) {
          }
        })
      }
    },
    // 点击确定
    dianji_queding () {
      if (this.qiye_renyuan_list_xuan.length != 0) {
        const list = []
        this.ziguanliyuan_bei = []
        for (let i = 0; i < this.qiye_renyuan_list_xuan.length; i++) {
          list.push(this.qiye_renyuan_list_xuan[i].staff_name)
          this.ziguanliyuan_bei.push(this.qiye_renyuan_list_xuan[i])
        }
        this.ziguanliyuan = list.join(',')
        this.qiye_bumen = false
      } else {
        this.$message({
          message: '请选择人员',
          type: 'warning'
        }) 
      }
    },
    keyu () {
      if (this.text.length == 0) {
        this.text = null
      }
      get_staff_list_like({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          staff_name: this.text,
          active: '1'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10027) {
          console.log(JSON.parse(res.data.body.data))
          const date = JSON.parse(res.data.body.data)
          // date.map(item => { item.zhi = false })
          this.qiye_renyuan_list = date
          this.qiye_renyuan_list.forEach(item => {
            if(!('name' in item)) {
              item.name = item.staff_name
            }
          })
          console.log(this.qiye_renyuan_list)
        } else if (res.data.code == 10028) {
        }
      })
    },
    // 表头颜色
    biaotou (row) { // 根据报警级别显示颜色
      return 'color:#1A2533;'
    },
    // color:#4C4A4D;font-size:0.12rem;
    liebiao (row) {
      return 'color:#4C4A4D;font-size:0.1rem;'
    },
    dianji_xuanze_mokuai (i, index) {
      console.log(i)
      console.log(index)
      this.modal_num = index
      this.mokuai_shengyu_num.map(item => {
        if (i.num == item.modal) {
          this.nengxuan_renshu = 3
          this.ziguanliyuan = item.staff_name
          this.ziguanliyuan_bei = item.staff_id
          this.qiye_renyuan_list_xuan = item.list != undefined ? item.list : []
        }
      })
      // qiye_renyuan_list_xuan
      // if(ren_list.length!=0){
      //     // for(let a=0;a<names.length;a++){
      //     //     list.push(
      //     //         {
      //     //             name:names[a],
      //     //             staff_id:ids[a],
      //     //             zhi:true
      //     //         }
      //     //     )
      //     // }
      //     this.qiye_renyuan_list_xuan=ren_list
      // }else{
      //     this.qiye_renyuan_list_xuan=[]
      // }
    },
    dianji_shanchu_yixuan (i) {
      for (let a = 0; a < this.qiye_renyuan_list_xuan.length; a++) {
        if (this.qiye_renyuan_list_xuan[a].staff_id == i.staff_id) {
          for (let b = 0; b < this.qiye_renyuan_list.length; b++) {
            if (this.qiye_renyuan_list[b].staff_id == i.staff_id) {
              this.qiye_renyuan_list[b].zhi = false
              console.log(this.qiye_renyuan_list[b].staff_id)
            }
          }
          console.log(a)
          console.log(this.qiye_renyuan_list_xuan[a].staff_id)
          console.log(i.staff_id)
          this.qiye_renyuan_list_xuan.splice(a, 1)
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.subadmin {
    background:#E7E8EB;
    height:100vh;
    .con{
        width: 100%;
        background:#E7E8EB;
        display: flex;
        align-items: center;
        padding-top:1.6rem;
        padding-bottom:0.18rem;
        .box{
            min-height:6.26rem;
            width:12rem;
            margin:0 auto;
            background: #fff;
            .p1{
                font-size: 0.18rem;
                color: #1A2533;
                font-weight: 400;
                margin: 0.2rem 0.42rem;
            }
            .nei{
                padding: 0 0.42rem;
                .nei_top{
                    height:0.42rem;
                    background:#E7E8EB;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 0.19rem;
                    p{
                        line-height: 0.25rem;
                        font-size: 0.12rem;
                        text-align: center;
                        padding: 0 0.16rem;
                        border-radius: 0.03rem;
                        cursor:pointer;
                    }
                    .p2{
                        color:#fff;
                        background:#8d52e3;
                    }
                    .p3{
                        color:#888888;
                        border:0.01rem solid #A9A9A9;
                        background: #fff;
                    }
                }
            }
        }
    }
    .tiao_bumen{
        display: flex;
        align-items: center;
        justify-content:center;
        .xuanze,.yixuan{
            height:3.54rem;
            width:3.85rem;
            background: #E7E8EB;
            margin: 0 0.11rem;
            margin-top: 0.2rem;
            position: relative;
            overflow-y:auto;
            &::-webkit-scrollbar {
                width: 0.2rem;
                height: 0.08rem;
            }
            
            &::-webkit-scrollbar-thumb {
                border-radius: 0.12rem;
                border: 0.06rem solid rgba(0, 0, 0, 0);
                box-shadow: 0.08rem 0 0 #A5ADB7 inset;
            }
            
            &::-webkit-scrollbar-thumb:hover {
                box-shadow: 0.08rem 0 0 #4A4A4A inset;
            }
            .tit{
                position: absolute;
                left:0;
                top:-0.3rem;
                font-size: 0.14rem;
                color:#1A2533;
            }
            .xuanze_sousuo{
                position: relative;
                i{
                    position: absolute;
                    top:0.09rem;;
                    left: 0.32rem;
                }
                input{
                    height:0.3rem;
                    width:2.74rem;
                    padding-left: 30px;
                    border:none;
                    background:#fff;
                    padding: 0 0.33rem;
                    margin: 0 auto;
                    display: block;
                    margin-top: 0.15rem;
                    border-radius:0.02rem;
                }
            }
            .mianbaoxie{
                display: flex;
                align-items: center;
                justify-content: left;
                margin-bottom: 0.1rem;
                margin-left: 0.23rem;
                margin-top:0.12rem;
                div{
                    display: flex;
                    align-items: center;
                    cursor:pointer;
                    p{
                        font-size: 0.14rem;
                    }
                    .p2{
                        color:#8d52e3;
                    }
                    .p1{
                        color:#1A2533;
                    }
                }
            }
            .quanxuan{
                display: flex;
                align-items: center;
                margin-left: 0.24rem;
                input{
                    -webkit-appearance: none;
                    width: 0.18rem!important;
                    height: 0.17rem!important;
                    display: inline-block;
                    margin: -0.03rem 0.05rem 0 0!important;
                    cursor: pointer;
                    vertical-align: middle;
                    background:url(../assets/noccc.png) no-repeat;
                    &:checked {
                        background: url(../assets/okblue.png) no-repeat;
                    }
                    outline-color:none;
                    outline-style :none;
                    outline-width:none;
                }
                span{
                    color:#1A2533;
                    font-size: 0.12rem;
                }
            }
            .nei_box{
                width:3.45rem;
                margin: 0 auto;
                max-height: 2.4rem;
                overflow:auto;
                // overflow-y: hidden;
                overflow-y: scroll;
                &::-webkit-scrollbar {
                    width: 0.19rem;
                    height: 0;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 0.12rem;
                    border: 0.06rem solid rgba(0, 0, 0, 0);
                    box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                }

                &::-webkit-scrollbar-thumb:hover {
                    box-shadow: 0.08rem 0 0 #4A4A4A inset;
                }
                .liebiao{
                    width:3.25rem;
                    margin: 0 auto;
                    ul{
                        li{
                            display: flex;
                            height:0.3rem;
                            justify-content: space-between;
                            align-items: center;
                            color:#1A2533;
                            font-size: 0.12rem;
                            padding-left: 0.1rem;
                            cursor:pointer;
                            &:hover{
                                background: #eee;
                            }
                            .img1{
                                height:0.11rem;
                                width:0.2rem;
                            }
                            input{
                                -webkit-appearance: none;
                                width: 0.18rem!important;
                                height: 0.17rem!important;
                                display: inline-block;
                                margin: -0.03rem 0.05rem 0 0!important;
                                cursor: pointer;
                                vertical-align: middle;
                                background:url(../assets/noccc.png) no-repeat;
                                &:checked {
                                    background: url(../assets/okblue.png) no-repeat;
                                }
                                outline-color:none;
                                outline-style :none;
                                outline-width:none;
                            }
                        }
                    }
                }
            }
        }
        .yixuan{
            .nei_box{
                width:3.45rem;
                margin: 0 auto;
                max-height: 3.5rem;
                overflow:auto;
                // overflow-y: hidden;
                overflow-y: scroll;
                &::-webkit-scrollbar {
                    width: 0.19rem;
                    height: 0;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 0.12rem;
                    border: 0.06rem solid rgba(0, 0, 0, 0);
                    box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                }

                &::-webkit-scrollbar-thumb:hover {
                    box-shadow: 0.08rem 0 0 #4A4A4A inset;
                }
                .liebiao{
                    width:3.25rem;
                    padding: 0.2rem 0;
                    margin: 0 auto;
                    ul{
                        li{
                            display: flex;
                            height:0.3rem;
                            justify-content: space-between;
                            align-items: center;
                            color:#1A2533;
                            font-size: 0.12rem;
                            padding: 0 0.1rem;
                            cursor:pointer;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            &:hover{
                                background: #eee;
                                i{
                                    display: block;
                                }
                            }
                            i{
                                cursor: pointer;
                                font-size: 0.14rem;
                                color: #ccc;
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .foote{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.21rem;
        p{
            margin: 0 0.23rem;
            height:0.43rem;
            width:1.36rem;
            border-radius: 0.02rem;
            font-size: 0.14rem;
            line-height: 0.43rem;
            text-align: center;
            font-weight: bold;
            cursor:pointer;
        }
        .queding{
            color:#fff;
            background: #8d52e3;
        }
        .quxiao{
            color: #888888;
            border:0.01rem solid #888888;
            background: #fff;
        }
    }
    @media screen and (max-width: 12rem) {
      .con {
        width: 12rem;
      }
    }
}
</style>
